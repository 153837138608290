import {React} from 'react';

export default function Mitgliedsantrag() {


    return (
        <>
        <div className='app-container'>
            <div className='content'>
              <h1  className='title'>Mitgliedsantrag</h1>
              <h2 className='subtitle'>Warum Mitglied werden?</h2>
              <p className='text'>
                Als Mitglied in unserem Verein kannst du aktiv dabei helfen, unsere Vorhaben umzusetzen. 
                Du profitierst auch von persönlichen Vorteilen wie exklusivem Zugang zu unseren Events, Vergünstigungen bei Ausfahrten,
                der Möglichkeit, dich mit anderen Mitgliedern zu vernetzen und vielem mehr. 
              </p>
              <p className='text'>
                Deine Mitgliedschaft wird anderen Mitgliedern und der Gemeinschaft insgesamt zugutekommen. 
              </p>
              <div className='download-section'>


                  <a href={`${process.env.PUBLIC_URL}/Mitgliedsantrag.pdf`} download className='download'>Download Mitgliedsantrag (PDF)</a> 
              </div>
              <p className='text'>Bitte sende den ausgefüllten Mitgliedsantrag an unsere Vorstandschaft unter folgender Emailadresse: <a href="mailto:info@schiclub-buch.de">info@schiclub-buch.de</a></p>
            </div>
        </div>
        </>   
    )
}