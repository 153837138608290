import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import './Cards';

function HeroSection() {
  
  // Custom smooth scroll function
  const smoothScrollTo = (targetId, duration) => {
    const target = document.getElementById(targetId);
    if (!target) return;

    const startPosition = window.scrollY;
    const targetPosition = target.getBoundingClientRect().top + startPosition;
    const distance = targetPosition - startPosition;
    const startTime = performance.now();

    const easeInOutQuad = (t) => 
      t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2;

    const animateScroll = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const ease = easeInOutQuad(progress);
      window.scrollTo(0, startPosition + distance * ease);

      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  };

  return (
    <div className='hero-container'>
      <h1>SCHICLUB BUCH</h1>

      <div className='hero-btns'>
      <Button
  className='btns'
  buttonStyle='btn--outline'
  buttonSize='btn--large'
  to='/mitgliedsantrag' // Only this button navigates
>
  MITGLIED WERDEN
</Button>

<Button
  className='btns'
  buttonStyle='btn--primary'
  buttonSize='btn--large'
  onClick={() => smoothScrollTo("section1", 800)} // Smooth scrolling for this button
>
  ENTDECKEN <i className='far fa-play-circle' />
</Button>

      </div>
    </div>
  );
}

export default HeroSection;
