import React, { useEffect } from 'react';
import Navbar from './components/Navbar'
import './App.css';
import { useLocation, BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home'
import Mitgliedsantrag from './components/pages/Mitgliedsantrag';
import Freizeiten from './components/pages/Freizeiten';
import Leistungen from './components/pages/Leistungen';
import Anfahrt from './components/pages/Anfahrt';
import Footer from './components/Footer';
import Impressionen from './components/pages/Impressionen';
import Aktuelles from './components/pages/Aktuelles'
import Datenschutz from './components/pages/Datenschutz'
import Impressum from './components/pages/Impressum'
//import Vorstandschaft from './components/pages/Vorstandschaft'
import Pitztal2025 from './components/pages/Pitztal2025'

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  
  return (
    <>
      <Router>
      <ScrollToTop />

        <Navbar />
        <Routes>
          <Route path='/' exact element={<Home />}></Route>
          <Route path='/mitgliedsantrag' element={<Mitgliedsantrag />}></Route>
          <Route path='/anfahrt' element={<Anfahrt />}></Route>
          <Route path='/leistungen' element={<Leistungen />}></Route>
          <Route path='/freizeit' element={<Freizeiten />}></Route>
          <Route path='/impressionen' element={<Impressionen />}></Route>
          <Route path='/aktuelles' element={<Aktuelles/>}></Route>
          <Route path='/datenschutz' element={<Datenschutz/>}></Route>
          <Route path='/impressum' element={<Impressum/>}></Route>
          {/* <Route path='/vorstand' element={<Vorstandschaft/>}></Route> */}
          <Route path='/freizeit/vergangen/skiausflug' element={<Pitztal2025 />}></Route>

        </Routes>
        <Footer/>
      </Router>

    </>


  );
}

export default App;