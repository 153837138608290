import React from 'react';

const Impressum = () => {
    return (
        <div className="app-container">
            <div className="content">
                <h1>Impressum</h1>

                <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                <p>Schi-Club-Buch e. V.<br />
                    Wolfsrain 2<br />
                    74744 Ahorn Buch</p>

                <p>Vereinsregister: VR 560 252<br />
                    Registergericht: Amtsgericht Mannheim</p>

                <p><strong>Vertreten durch:</strong><br />
                    1. Vorsitzender: Torsten Benz<br />
                    2. Vorsitzender: Timo Hofmann</p>

                <h2>Kontakt</h2>
                <p>Telefon Torsten: 0170/5558285<br />
                <p>E-Mail Schiclub: <a href="mailto:info@schiclub-buch.de">info@schiclub-buch.de</a></p>
                    E-Mail Timo: timo.hofmann.83@t-online.de</p>

                <h2>Umsatzsteuer-ID</h2>
                <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
                    40001 / 05655</p>

                <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
            </div>
        </div>
    );
};

export default Impressum;
